header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16vh;
  width: 100vw;
  background-color: white;
  border-bottom: 1px solid black;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  overflow: hidden;
}

#logo,
.LogoText h1,
h4 {
  transition: all ease 0.5s;
}

nav {
  display: flex;
  align-items: center;
  text-align: center;
}
#logo {
  width: 5.5rem;
  height: 5.5rem;
  object-fit: contain;
  margin-left: 1.5rem;
}

.LogoText > h1,
h4 {
  margin: 0;
  letter-spacing: 1px;
}
.LogoText h1 {
  font-size: 1.8rem;
  color: #01830f;
}
.LogoText h4 {
  font-size: 1rem;
  color: #707070;
}
.NavLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}
.NavLinks ul {
  display: flex;
}
.NavLinks ul li {
  list-style: none;
  margin: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}
.NavLinks ul li a {
  position: relative;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: black;
  padding: 0.5rem 0;
}
.NavLinks ul li a::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 0.2rem;
  width: 100%;
  background-color: #01830f;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s linear;
}
.NavLinks ul li a:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

#lsButton {
  margin: 0.5rem;
  width: 10rem;
  height: 2.5rem;
  border: 1px solid gray;
  border-radius: 5rem;
  font-size: 1.1rem;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #63dd6f;
  cursor: pointer;
  transition: all ease 0.3s;
}
#lsButton:hover {
  box-shadow: 0 6px 10px -5px rgb(170, 170, 170);
  letter-spacing: 1px;
}
/* Mobile */
.MLogoText h1 {
  font-size: 1.2rem;
}
.MLogoText h4 {
  font-size: 0.8rem;
}
