#error-page .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content h2.header {
  font-size: 10rem;
  line-height: 1em;
  position: relative;
}
.content h2.header:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    #71b7e6,
    #69a6ce,
    #b98acc,
    #ee8176,
    #b98acc,
    #69a6ce,
    #9b59b6
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
}

.content h4 {
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;
}
.content h4:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content p {
  font-size: 1.2em;
  color: #0d0d0d;
  text-align: center;
}
.content .btns {
  margin: 25px 0;
  display: inline-flex;
}
.content .btns a {
  display: inline-block;
  text-decoration: none;
  border: 2px solid #69a6ce;
  color: #69a6ce;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.content .btns a:hover {
  font-weight: bolder;
  background: lightgreen;
  color: #fff;
}
