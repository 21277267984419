@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&display=swap');
html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  transition: all ease 0.5s;
}
body::-webkit-scrollbar {
  display: none;
}
h1 {
  font-family: 'Josefin Sans', sans-serif;
}
h4 {
  font-family: 'Roboto', sans-serif;
}
