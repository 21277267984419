.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}
.Header {
  position: fixed;
  z-index: 1;
}
.Home,
.Menu,
.Tiffin,
.About,
.ContactUs {
  position: relative;
  top: 16vh;
}
.ErrorPage {
  position: relative;
  top: 8vh;
}
.Footer {
  position: relative;
  margin-top: auto;
  top: 16vh;
}
