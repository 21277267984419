@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100&display=swap');
.Menucontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  background-color: lightgreen;
  border-top: 3px solid gray;
}
/* #thirdblockBG {
  width: 100%;
  height: 110vh;
  object-fit: cover;
} */
/* .Menucontent {
  position: absolute;
  bottom: 10vh;
} */
.OurmealImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
}
#OurmealPlanImg {
  width: 50%;
  margin: 0.5rem;
  object-fit: contain;
}
.MenuTimetext h1 {
  margin: 1.5rem 0;
  font-size: 2rem;
  /* text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff; */
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
}

.tiffinImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tiffinImageContainer div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuImg {
  width: 70%;
  margin: 1rem;
  object-fit: contain;
  transition: all ease 0.5s;
  margin: 0;
  padding: 0;
}

.menuBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

/* Mobile View 
@media (max-width: 360px) and (min-width: 360px) {
}
*/
