.csComtainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}
.csComtainer h1 {
  font-size: 5vw;
}
.csComtainer h4 {
  font-size: 3vw;
}
.csComtainer a {
  display: inline-block;
  text-decoration: none;
  border: 2px solid #69a6ce;
  color: #69a6ce;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.csComtainer a:hover {
  background: lightgreen;
  color: #fff;
}
