@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
.mainContainer {
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;
}
#whyUs {
  width: 100%;
  text-align: center;
}
#whyUs h4 {
  font-family: 'Satisfy', cursive;
  font-size: 5rem;
  font-weight: normal;
  text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.3);
}
/* .whyUsContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
} */

.circles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 26rem;
  width: 26rem;
  background-color: #838080;
  text-align: center;
  border-radius: 50%;
  transition: all ease 0.5s;
}
.circles p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.3rem;
  width: 25rem;
  text-align: center;
  color: white;
  line-height: 1.5rem;
  letter-spacing: 1px;
}

.circles h4 {
  font-size: 3rem;
  font-family: 'Satisfy', cursive;
  font-weight: normal;
  text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.3);
  color: white;
  cursor: pointer;
}
.circles h4:hover {
  text-decoration: underline;
}
.bottomImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomImg img {
  width: 50%;
  margin: 1rem;
  object-fit: cover;
  transition: all ease 0.5s;
}
