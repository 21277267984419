@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&display=swap');
footer {
  width: 100vw;
  background-color: #1a1512;
  color: white;
  font-weight: bold;
  line-height: 1.5rem;
  overflow: hidden;
}
.footerSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}
.contactUs {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.1px;
}
.contactUs a {
  color: white;
}
.logSign {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.1px;
}
.branding {
  height: 8vh;
  width: inherit;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
}
.branding div {
  margin: 0rem 2rem;
  padding: 1rem;
  letter-spacing: 1.2px;
}
.branding a {
  color: white;
}
.mBrandview h5,
h6 {
  margin: 0;
  letter-spacing: 1.1px;
  font-family: 'Josefin Sans', sans-serif;
}
.mBrandview h5 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.mBrandview h6 {
  font-size: 0.8rem;
}
