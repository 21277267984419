.HeroBanner {
  width: 100%;
  height: 90vh;
}
#HomePageBG {
  width: 100%;
  height: inherit;
  object-fit: cover;
}
.BannerCon {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 5vh;
  transition: all ease 0.5s;
}
#Banner {
  width: 60%;
  object-fit: contain;
}
.bannerTextCon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bannerText {
  position: absolute;
  top: 50vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin: 4rem 2rem;
  transition: all ease 0.5s;
}
.bannerText h4,
input[type='button'] {
  text-align: center;
}
.bannerText h4 {
  color: white;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0.5rem;
}
.orderNowBtn {
  width: 50%;
  margin: 0;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
