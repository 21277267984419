@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100&display=swap);
html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  transition: all ease 0.5s;
}
body::-webkit-scrollbar {
  display: none;
}
h1 {
  font-family: 'Josefin Sans', sans-serif;
}
h4 {
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}
.Header {
  position: fixed;
  z-index: 1;
}
.Home,
.Menu,
.Tiffin,
.About,
.ContactUs {
  position: relative;
  top: 16vh;
}
.ErrorPage {
  position: relative;
  top: 8vh;
}
.Footer {
  position: relative;
  margin-top: auto;
  top: 16vh;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16vh;
  width: 100vw;
  background-color: white;
  border-bottom: 1px solid black;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  overflow: hidden;
}

#logo,
.LogoText h1,
h4 {
  transition: all ease 0.5s;
}

nav {
  display: flex;
  align-items: center;
  text-align: center;
}
#logo {
  width: 5.5rem;
  height: 5.5rem;
  object-fit: contain;
  margin-left: 1.5rem;
}

.LogoText > h1,
h4 {
  margin: 0;
  letter-spacing: 1px;
}
.LogoText h1 {
  font-size: 1.8rem;
  color: #01830f;
}
.LogoText h4 {
  font-size: 1rem;
  color: #707070;
}
.NavLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}
.NavLinks ul {
  display: flex;
}
.NavLinks ul li {
  list-style: none;
  margin: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}
.NavLinks ul li a {
  position: relative;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: black;
  padding: 0.5rem 0;
}
.NavLinks ul li a::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 0.2rem;
  width: 100%;
  background-color: #01830f;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right;
          transform-origin: right;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}
.NavLinks ul li a:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: left;
          transform-origin: left;
}

#lsButton {
  margin: 0.5rem;
  width: 10rem;
  height: 2.5rem;
  border: 1px solid gray;
  border-radius: 5rem;
  font-size: 1.1rem;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #63dd6f;
  cursor: pointer;
  transition: all ease 0.3s;
}
#lsButton:hover {
  box-shadow: 0 6px 10px -5px rgb(170, 170, 170);
  letter-spacing: 1px;
}
/* Mobile */
.MLogoText h1 {
  font-size: 1.2rem;
}
.MLogoText h4 {
  font-size: 0.8rem;
}

footer {
  width: 100vw;
  background-color: #1a1512;
  color: white;
  font-weight: bold;
  line-height: 1.5rem;
  overflow: hidden;
}
.footerSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}
.contactUs {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.1px;
}
.contactUs a {
  color: white;
}
.logSign {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.1px;
}
.branding {
  height: 8vh;
  width: inherit;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
}
.branding div {
  margin: 0rem 2rem;
  padding: 1rem;
  letter-spacing: 1.2px;
}
.branding a {
  color: white;
}
.mBrandview h5,
h6 {
  margin: 0;
  letter-spacing: 1.1px;
  font-family: 'Josefin Sans', sans-serif;
}
.mBrandview h5 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.mBrandview h6 {
  font-size: 0.8rem;
}

.homContainer {
  height: 100%;
  width: 100%;
}

.HeroBanner {
  width: 100%;
  height: 90vh;
}
#HomePageBG {
  width: 100%;
  height: inherit;
  object-fit: cover;
}
.BannerCon {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 5vh;
  transition: all ease 0.5s;
}
#Banner {
  width: 60%;
  object-fit: contain;
}
.bannerTextCon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bannerText {
  position: absolute;
  top: 50vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin: 4rem 2rem;
  transition: all ease 0.5s;
}
.bannerText h4,
input[type='button'] {
  text-align: center;
}
.bannerText h4 {
  color: white;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0.5rem;
}
.orderNowBtn {
  width: 50%;
  margin: 0;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.mainContainer {
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;
}
#whyUs {
  width: 100%;
  text-align: center;
}
#whyUs h4 {
  font-family: 'Satisfy', cursive;
  font-size: 5rem;
  font-weight: normal;
  text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.3);
}
/* .whyUsContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
} */

.circles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 26rem;
  width: 26rem;
  background-color: #838080;
  text-align: center;
  border-radius: 50%;
  transition: all ease 0.5s;
}
.circles p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.3rem;
  width: 25rem;
  text-align: center;
  color: white;
  line-height: 1.5rem;
  letter-spacing: 1px;
}

.circles h4 {
  font-size: 3rem;
  font-family: 'Satisfy', cursive;
  font-weight: normal;
  text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.3);
  color: white;
  cursor: pointer;
}
.circles h4:hover {
  text-decoration: underline;
}
.bottomImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomImg img {
  width: 50%;
  margin: 1rem;
  object-fit: cover;
  transition: all ease 0.5s;
}

.Menucontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  background-color: lightgreen;
  border-top: 3px solid gray;
}
/* #thirdblockBG {
  width: 100%;
  height: 110vh;
  object-fit: cover;
} */
/* .Menucontent {
  position: absolute;
  bottom: 10vh;
} */
.OurmealImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
}
#OurmealPlanImg {
  width: 50%;
  margin: 0.5rem;
  object-fit: contain;
}
.MenuTimetext h1 {
  margin: 1.5rem 0;
  font-size: 2rem;
  /* text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff; */
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
}

.tiffinImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tiffinImageContainer div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuImg {
  width: 70%;
  margin: 1rem;
  object-fit: contain;
  transition: all ease 0.5s;
  margin: 0;
  padding: 0;
}

.menuBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

/* Mobile View 
@media (max-width: 360px) and (min-width: 360px) {
}
*/

.MenuItems {
  margin: 3rem 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.MenuItems a {
  text-decoration: none;
}

.csComtainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}
.csComtainer h1 {
  font-size: 5vw;
}
.csComtainer h4 {
  font-size: 3vw;
}
.csComtainer a {
  display: inline-block;
  text-decoration: none;
  border: 2px solid #69a6ce;
  color: #69a6ce;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.csComtainer a:hover {
  background: lightgreen;
  color: #fff;
}

#error-page .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content h2.header {
  font-size: 10rem;
  line-height: 1em;
  position: relative;
}
.content h2.header:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    #71b7e6,
    #69a6ce,
    #b98acc,
    #ee8176,
    #b98acc,
    #69a6ce,
    #9b59b6
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
}

.content h4 {
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;
}
.content h4:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content p {
  font-size: 1.2em;
  color: #0d0d0d;
  text-align: center;
}
.content .btns {
  margin: 25px 0;
  display: inline-flex;
}
.content .btns a {
  display: inline-block;
  text-decoration: none;
  border: 2px solid #69a6ce;
  color: #69a6ce;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.content .btns a:hover {
  font-weight: bolder;
  background: lightgreen;
  color: #fff;
}

